import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AppDashboardService } from './app.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

      state:number=1;

     static DASHBOARD_STATE:number=0;
     static    PRESENTATION_STATE:number=1;


  lastFlipTimeStamp:Date;
 // interval2:number = 0.34*60*1000;

 interval2:number=100*1000;

interval;
presentationDuration=1000* (10)  ;

 dashboardDuration:number=60*1000*10  ;

 showingPowerPoint=false;



    constructor(private router:Router, private appService: AppDashboardService) {  }
    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
     
      
        if (event.key== "+") { 

            this.forceClick();


            this.showingPowerPoint=!this.showingPowerPoint;

     if ( this.showingPowerPoint) {  
          alert("You've turned ON floor dashboard mode! Press + to revert");

        } else {
            alert("You've turned OFF floor dashboard mode! Press + to revert");
        }
    }
    }

    ngOnInit() {

        this.lastFlipTimeStamp=new Date();
     //   var parent = document.getElementById('container1');
      //  var child = document.getElementById('container2');
      //  child.style.right = child.clientWidth - child.offsetWidth + "px";
      
    //    this.interval =setInterval ( ()=> {  if ( this.showingPowerPoint){ this.click()}}, 3000 );
        
    }

    
    

    click () {

        let dur = new Date();

        var offset:any = dur.getTime()-this.lastFlipTimeStamp.getTime();


        if (  this.state == AppComponent.DASHBOARD_STATE) {

            let diff = dur.getTime() - this.lastFlipTimeStamp.getTime();

           
            if ( diff > this.dashboardDuration ) {

            

                
                this.state = AppComponent.PRESENTATION_STATE;

                this.lastFlipTimeStamp=new Date();

                

                console.log( "Switched to presentation");

                this.router.navigate(['/slideshow']);

            }
        }

        else if (this.state == AppComponent.PRESENTATION_STATE ) {

            let diff = dur.getTime() - this.lastFlipTimeStamp.getTime();

        
            if ( diff > this.presentationDuration ) {
                 
                this.state = AppComponent.DASHBOARD_STATE;

                this.lastFlipTimeStamp=new Date();

             console.log( "Switched to dashboards");
                this.router.navigate(['']);

            }



        }
    }

    forceClick () {

        
     
                this.router.navigate(['/slideshow']);

            
       
    }
}
