import { Injectable, Component, EventEmitter, Output , OnInit
} from '@angular/core';

import {HttpClient, HttpParams} from '@angular/common/http';

 
import { unescapeIdentifier } from '@angular/compiler';

@Injectable()
export class AppDashboardService {
   @Output() notificationsUpdated: EventEmitter<any> = new EventEmitter<any>();

 
  public tickets:Array<any>;


  public  agentNameMap : Map< string, string> ;


  public todaysTickets: Array<any>;

  public leaderBoard:Array<any>;
  public resolution:Array<any>;
  public fiveStars:Array<any>;
 
  public SRRLeaderBoard:Array<any>;
  public SRRTeamLeaderBoard:Array<any>;

  public hourlyCount:number=0;
  public dailyCount:number=0;

  public saveData=false;

public slideShowArray:Array<any>;
public shouldShowPPT=false;


 constructor(  private http : HttpClient
   ) {

 
}


public SaveData ( hourly:number, day:number) {
    this.dailyCount = day;
    this.hourlyCount=hourly;

 
}

}