import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';

const routes: Routes = [
    {path:'' ,loadChildren:'./scout-dashboard/scoutDashboard.module#ScoutDashboardModule'},
    {path:'slideshow' ,loadChildren:'./slide-show/slide-show.module#SlideShowModule'},
    { path: 'dashboard', loadChildren: './layout/layout.module#LayoutModule', canActivate: [AuthGuard] },
    { path: 'login', loadChildren: './login/login.module#LoginModule' },
    { path: 'signup', loadChildren: './signup/signup.module#SignupModule' },
    { path: 'pqa', loadChildren: './pqa/pqa.module#PQAModule' },
   
    { path: 'error', loadChildren: './server-error/server-error.module#ServerErrorModule' },
    { path: 'access-denied', loadChildren: './access-denied/access-denied.module#AccessDeniedModule' },
    { path: 'not-found', loadChildren: './not-found/not-found.module#NotFoundModule' },
    { path: 'presentation', loadChildren: './powerPoint/powerpoint.module#PowerPointModule' },
    { path: 'data-entry', loadChildren: './manual-entry/manual-entry.module#ManualEntryModule' },
    { path: '**', redirectTo: 'not-found' }
 

  
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
